<template>
    <div>
        <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"></Toolbar>
        <BackendGroupForm :errors="violations" :values="item" ref="createForm"/>
        <Loading :visible="isLoading"/>
    </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import BackendGroupForm from '../../components/backendgroup/Form';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import CreateMixin from '../../mixins/CreateMixin';

  const servicePrefix = 'BackendGroup';

  const { mapFields } = createHelpers({
    getterType: 'backendGroup/getField',
    mutationType: 'backendGroup/updateField'
  });

  export default {
    name: 'BackendGroupCreate',
    servicePrefix,
    mixins: [CreateMixin],
    components: {
      Loading,
      Toolbar,
      BackendGroupForm
    },
    data () {
      return {
        item: {}
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'violations'])
    },
    methods: {
      ...mapActions('backendGroup', ['create', 'reset'])
    }
  };
</script>
